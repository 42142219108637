'use client'

import classnames from 'classnames'
import styles from './ThreeUpLocationCard.module.scss'
import SanityImage from '@/components/SanityImage/SanityImage'
import useI18n from '@/hooks/use-i18n'
import { getRestaurantAddressString } from '@/utils'
import RestaurantOpenClosedBlurb from '@/components/RestaurantOpenClosedBlurb/RestaurantOpenClosedBlurb'
import Link from '@/components/Link/Link'
import Widont from '@/components/Widont/Widont'
import AnimateContainer from '@/components/AnimateContainer/AnimateContainer'

type ThreeUpLocationCardProps = SanityPage & {
  className?: string
}

const ThreeUpLocationCard = ({ className, locationData, title, slug }: ThreeUpLocationCardProps) => {
  const { i18n } = useI18n()

  const hoursFormatted = typeof locationData?.hours === 'string' ? JSON.parse(locationData?.hours) : locationData?.hours
  const addressString = getRestaurantAddressString({
    streetAddress: locationData?.streetAddress,
    city: locationData?.city,
    state: i18n(locationData?.state as string),
    postalCode: locationData?.postalCode,
  })

  if (!locationData || !locationData?.featuredImage || !title) return null

  return (
    <AnimateContainer
      element="article"
      animationType="fadeIn"
      className={classnames(styles.ThreeUpLocationCard, className)}
    >
      <Link
        link={{
          label: '',
          linkType: 'internal',
          link: {
            _type: 'location',
            slug: slug.current,
          },
        }}
      >
        <div className={styles.imageContainer}>
          <SanityImage
            className={styles.image}
            image={locationData?.featuredImage}
            breakpoints={{
              tablet: {
                image: locationData?.featuredImage,
                width: 900,
                options: {
                  height: 1000,
                  fit: 'crop',
                },
              },
              xs: {
                image: locationData?.featuredImage,
                width: 800,
                options: {
                  height: 950,
                  fit: 'crop',
                },
              },
            }}
          />
        </div>
        <p className={styles.title}>
          <Widont text={title} />
        </p>
        <RestaurantOpenClosedBlurb
          className={styles.openClosedBlurb}
          hours={hoursFormatted}
          timeZone={locationData?.timeZone}
          holidayHours={locationData?.holidayHours}
          isComingSoon={locationData?.isComingSoon}
          isTemporarilyClosed={locationData?.isTemporarilyClosed}
        />
        {locationData?.phoneNumber && (
          <p className={styles.phoneNumber}>{i18n('phoneNumberShort', { phoneNumber: locationData?.phoneNumber })}</p>
        )}
        {addressString && (
          <p className={styles.address}>
            {i18n('addressShort', {
              address: addressString,
            })}
          </p>
        )}
      </Link>
    </AnimateContainer>
  )
}

ThreeUpLocationCard.displayName = 'ThreeUpLocationCard'

export default ThreeUpLocationCard
