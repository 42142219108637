import { getMainDataOverrides, getMergedLanguageQueryString } from '@/data/sanity/utils'
import { imageAsset } from '@/data/sanity/fragments'

export const fields = `
  locationData {
    ${getMergedLanguageQueryString('LocationData', [
      'isComingSoon',
      'isTemporarilyClosed',
      'heroDescription',
      { name: 'featuredImage', fields: imageAsset.fieldsWithDataUri },
      { name: 'images', fields: imageAsset.fieldsWithDataUri, isArray: true },
      'areaTitle',
      'phoneNumber',
      {
        name: 'reservationLinks',
        isArray: true,
        fields: `
          serviceType,
          url
        `,
      },
      {
        name: 'serviceLinks',
        isArray: true,
        fields: `
          serviceType,
          url
        `,
      },
      'isReservationOnly',
      'hasPrivateDining',
      'googleMapsLink',
      'timeZone',
      'streetAddress',
      'city',
      'state',
      'postalCode',
      'hours',
      {
        name: 'holidayHours',
        isArray: true,
        fields: `
          title,
          date,
          isClosed,
          openingHour,
          closingHour
        `,
      },
    ])}
  },
  ${getMainDataOverrides({
    objectSuffix: 'LocationData',
    mainObjectName: 'locationData',
    mainDataOverrides: ['title'],
  })}
`

export const fragment = (name = 'locationData') => `${name}{${fields}}`

const exported = {
  fields,
  fragment,
}

export default exported
