'use client'

import classnames from 'classnames'
import styles from './JoinWaitlist.module.scss'
import Button from '@/components/Button/Button'
import useI18n from '@/hooks/use-i18n'

type JoinWaitlistProps = {
  className?: string
  waitlistTime: string
  primary?: boolean
  secondary?: boolean
  onClick?: () => void
  businessId?: string
  waitlistStatus?: string
}

const JoinWaitlist = ({
  className,
  waitlistTime,
  primary,
  secondary,
  onClick,
  businessId,
  waitlistStatus,
}: JoinWaitlistProps) => {
  const { i18n } = useI18n()

  const buttonLabel = () => {
    const labels = {
      CLOSED: i18n('joinWaitlistClosed'),
      OPEN: `${i18n('joinWaitlist', { time: waitlistTime })} ${waitlistTime} mins`,
      NO_WAIT: i18n('joinWaitlistFree'),
      LOADING: i18n('joinWaitlistLoading'),
    }

    if (waitlistStatus === 'LOADING') {
      return labels.LOADING
    }

    if (waitlistStatus === 'CLOSED') {
      return labels.CLOSED
    }

    const hasWaitTime = waitlistTime && Number(waitlistTime) !== 0
    return hasWaitTime ? labels.OPEN : labels.NO_WAIT
  }
  return (
    <div className={classnames(styles.JoinWaitlist, className)}>
      <Button
        onClick={onClick}
        primary={primary}
        secondary={secondary || !primary}
        label={buttonLabel()}
        link={
          businessId
            ? ({ link: `https://www.yelp.com/waitlist/${businessId}`, linkType: 'external' } as SanityLink)
            : undefined
        }
        className={styles.button}
      />
    </div>
  )
}

JoinWaitlist.displayName = 'JoinWaitlist'

export default JoinWaitlist
